import React, { ReactElement } from "react";
import Heading from "../../components/heading";

export interface FeaturesListProps { }

export default function HCSec3(props: FeaturesListProps): ReactElement | null {

  const data = [
    {
      title: "Instant Credit",
      desc: "Wählen Sie den gewünschten Kreditbetrag und die Zeit aus Zeitraum, in dem Sie Ihr Darlehen zurückzahlen möchten."
    },
    {
      title: "Express Loan",
      desc: "Wählen Sie den gewünschten Kreditbetrag und die Zeit aus Zeitraum, in dem Sie Ihr Darlehen zurückzahlen möchten."
    },
    {
      title: "Auto Loan",
      desc: "Geben Sie Ihre persönlichen Daten ein, wie Name, E-Mail, Telefonnummer und ähnliche Einzelheiten. Wir geben Ihre Daten an niemanden weiter! (außer Kreditgeber)"
    },
    {
      title: "Self Employed Loan",
      desc: "Geben Sie Ihre persönlichen Daten ein, wie Name, E-Mail, Telefonnummer und ähnliche Einzelheiten. Wir geben Ihre Daten an niemanden weiter! (außer Kreditgeber)"
    },
    {
      title: "Financial Restructuring",
      desc: "Geben Sie Ihre persönlichen Daten ein, wie Name, E-Mail, Telefonnummer und ähnliche Einzelheiten. Wir geben Ihre Daten an niemanden weiter! (außer Kreditgeber)"
    },
    {
      title: "Low Income, Without Proof",
      desc: "Geben Sie Ihre persönlichen Daten ein, wie Name, E-Mail, Telefonnummer und ähnliche Einzelheiten. Wir geben Ihre Daten an niemanden weiter! (außer Kreditgeber)"
    },
    {
      title: "Debt Consolidation",
      desc: "Geben Sie Ihre persönlichen Daten ein, wie Name, E-Mail, Telefonnummer und ähnliche Einzelheiten. Wir geben Ihre Daten an niemanden weiter! (außer Kreditgeber)"
    },
  ]

  return (
    <>

      <div className="-max-w-1366 mx-auto p-8  w-full  my-32">
        <div className="text-center">
          <Heading position="center" >
            <h1>
              Our Products
            </h1>
          </Heading>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 flex items-center justify-center">
            <img className="mt-8 max-h-96" src={require("../../images/our-product-left.png").default} />
          </div>
          <div
            className="flex-1 mt-16 md:mt-24 pl-16 md:pl-32"
          >
            <ul>{data.map((item, index) => <li className="mb-4 flex items-center" key={index}>
              <img src={require("../../images/cog.png").default} />
              <h3 className="pl-2">{item.title}</h3>
            </li>)}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
