import React, { ReactElement } from "react";
import SlickSlider from "../../components/slick";
import Featuer1Icon from "../../images/phone-call.png";
import Featuer2Icon from "../../images/share.png";
import Featuer3Icon from "../../images/email.png";
import Featuer4Icon from "../../images/internet-activities.png";
import Button from "../../components/button";
import Utils from "../../utils";
import { Link } from "gatsby";

interface IFeature {
  icon: any;
  title: string;
  description: string;
  bg?: string;
}
export interface FeaturesProps { }

export default function Features(props: FeaturesProps): ReactElement | null {
  const features: IFeature[] = [
    {
      icon: Featuer1Icon,
      title: "Interest rates that are flexible and appealing",
      description: `With us, you get a low, competitive, flexible, and cost-effective interest rate on your loan.`,
      bg: "bg-teal-100"
    },
    {
      icon: Featuer2Icon,
      title: "Fast Processing",
      description: `Follow a simple method to obtain the loan amount.`,
      bg: "bg-yellow-100"
    },
    {
      icon: Featuer3Icon,
      title: "100% Online and Automated",
      description: `With us, getting a loan is no longer a chore. No time-consuming documentation is necessary. You can apply for credit online.`,
      bg: "bg-gray-100"
    },
    {
      icon: Featuer4Icon,
      title: "Loans upto $50,000",
      description: `Get a guaranteed instant loan with no security and no extra fees here with ease. `,
      bg: "bg-pink-100"
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
  return (
    <>
      <div className="-max-w-1366 mx-auto p-10 bg-gray-50"  >
        <SlickSlider settings={settings}>
          {features.map((feature, index) => (
            <div key={index}
              className="flex justify-center items-center p-8 "
            >
              <div className={"mb-4 bg-white shadow-md hover:shadow-xl "}
                style={{
                  height: "300px"
                }}
              >
                <div className={"p-4  text-sm flex flex-col items-center text-center font-bold "}>
                  <img src={feature.icon} className={"w-24 h-24 rounded-full p-4 " + feature.bg} />
                  <div className={"w-full py-4 " + feature.bg}>
                    <h5 >
                      {feature.title}
                    </h5>
                  </div>
                </div>
                <div className="p-2">
                  <p className="text-justify px-2">
                    {feature.description}
                  </p>
                </div>
              </div>

            </div>
          ))}
        </SlickSlider>
        <div className="flex justify-center mt-12">
          <Link to="/apply">
            <Button title={"APPLY NOW"}></Button>
          </Link>
        </div>
      </div>
    </>
  )
}
