import React, { ReactElement } from "react";
import Achivement from "../../Achivement-banner";
import Heading from "../../components/heading";
import FAQs from "../../hoc/faqs";
import Features from "../../hoc/features";
import HCSec3 from "../../hoc/hc-sec-3";
import HCSec5 from "../../hoc/hc-sec-5";
import Hero from "../../hoc/hero";
import LegalInfo from "../../hoc/legal-info";
import ApplicationContainer from "../application-form";
import "./index.css";


export interface HomeContainerProps { }


export default function HomeContainer(
  props: HomeContainerProps
): ReactElement | null {

  return (
    <>
      <Hero />
      <div id="apply-now" className="py-16 text-center">
        <Heading position="center">
          <h1 className="pb-2">Apply in seconds...</h1>
        </Heading>
        <ApplicationContainer />
      </div>
      <div >
        <HCSec3 />
      </div>
      <div>
        <Achivement />
      </div>
      <div >
        <Features />
      </div>
      <div id="why-us">
        <HCSec5 />
      </div>
      <div>
        {/* <Testimonials /> */}
      </div>
      <div id="legal-info">
        <LegalInfo />
      </div>
      <div>
        <FAQs />
      </div>
    </>
  )
}
