import { Link } from "gatsby";
import React, { ReactElement } from "react";
import Button from "../../components/button";
import EMICalculator from "../../hoc/emi-calc";

export interface HeroProps { }

export default function Hero(props: HeroProps): ReactElement | null {
  const data = [
    {
      title: "Quickest and easiest payouts, in the industry!"
    },
    {
      title: "Experienced processing with discretion"
    },
    {
      title: "Loans for a minimum of 2 months and a maximum of 84 months"
    },
    {
      title: "Loans at APR 7.95%-25.95%"
    },
    {
      title: "Loans up to $50,000 with no hidden costs"
    }
  ]
  return (
    <div
      className="min-h-screen  items-center  w-full overflow-hidden bg-center bg-cover relative   "
      style={{
        backgroundImage: `url(${require("../../images/hero-bg-left.jpg").default})`,
      }}
    >
      <div
        style={{
          // background: "linear-gradient(90deg, rgba(0,51,107,1) 0%, rgba(0,0,0,0) 100%)",
          background: "linear-gradient(90deg, rgba(0,33,22,0.9) 0%, rgba(0,50,34,0.7) 100%)"
        }}
        className="absolute  z-10 w-full h-full"
      />
      <div
        className="min-h-screen  items-center  flex bg-center bg-cover flex-col -max-w-1366 md:flex-row   mx-auto p-8 ">
        <div
          className="flex-1 items-center relative z-10  bg-center flex w-full md:w-2/3">

          <img style={{
            width: "700px"
          }} src={require("../../images/hero-fg.svg").default} />

        </div>
        <div className="md:pl-12 relative flex-1 flexjustify-center  mx-auto ">
          <div className="relative text-white z-20 flex flex-col justify-center p-8 md:p-0 ">
            {/* <div className="opacity-0 flex flex-row justify-center md:justify-start">
              <img src={require("../../images/logo.svg").default} />
            </div> */}
            <div className="pt-8">
              <div className="text-center md:text-left">
                <h1 className="text-3xl md:text-4xl">Best lending service <br />in the industry...</h1>
                <h4>Dispatching loans in minutes...</h4>
              </div>
              <ul className="mt-4">
                {data.map((item, index) => <li className="mb-1 flex items-start" key={index}>
                  <img className="w-4 h-4 mt-1" src={require("../../images/cog.png").default} />
                  <span className="pl-2">{item.title}</span>
                </li>)}
              </ul>
              <div className="flex flex-row justify-center md:justify-start">
                {/* <Button onClick={() => { Utils.Commons.scrollTo("apply-now") }} className="mt-8" title="APPLY NOW"></Button> */}
                <Link to="/apply">
                  <Button className="mt-8" title="APPLY NOW"></Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div >
  );
}
