import { Link } from "gatsby";
import React, { ReactElement } from "react";
import Button from "../../components/button";
import Heading from "../../components/heading";
import Utils from "../../utils";

export interface HCSec5Props { }

export default function HCSec5(props: HCSec5Props): ReactElement | null {
  const data = [
    {
      title: "Free Forever, no hidden charges",
      desc: "There are no origination, closing, or prepayment penalties. There are no fees if you pay on time.",
      icon: require("../../images/free-to-use.svg").default,
      color: "#00D4E6"
    },
    {
      title: "A simple application form to start",
      desc: "With our fast application, you can get started in minutes. That means we'll be ready when you are.",
      icon: require("../../images/simple-form.svg").default,
      color: "#8759F2"
    },
    {
      title: "Quick, safe, and always there for you",
      desc: "Our team is here to assist you in achieving your financial objectives. Our Client Success team is ready to assist you every step of the way.",
      icon: require("../../images/fast-secure.svg").default,
      color: "#FF3E80"
    },
    {
      title: "Large base of lenders, and options",
      desc: "We can put you in touch with lenders who may be able to help you. You might be able to connect with a lender via our service, regardless of your credit history.",
      icon: require("../../images/more-lenders.svg").default,
      color: "#FFC300"
    },

  ]
  return (
    <>
      <div className=" -max-w-1366 mx-auto p-8 mt-16">
        <Heading position="center">
          <h2 className="text-center">
            Why Choose Us?
          </h2>
        </Heading>
        <div
          className="mt-16 mx-auto"
          style={{ maxWidth: "1024px" }}
        >
          <div className="flex flex-wrap">
            {data.map((item, index) => <div className="w-full md:w-1/2 mb-8" key={index}>
              <div className="flex items-start " >
                <img src={item.icon} />
                <div className="pl-2">
                  <div className="flex items-end mb-2">
                    <h1 style={{ color: item.color }} className="text-6xl pr-1" >{item.title && item.title.length ? item.title.substring(0, 1) : ""}</h1>
                    <h4 >{item.title}</h4>
                  </div>
                  <p className="">{item.desc}</p>
                </div>
              </div>

            </div>)}
          </div>
        </div>
      </div>
      <div className="flex justify-center ">
        <Link to="/apply">
          <Button title={"APPLY NOW"}></Button>
        </Link>
      </div>
    </>
  );
}
