
import React, { ReactElement } from "react";

export interface AchivementDataProps { }

export default function Achivement(props: AchivementDataProps): ReactElement | null {
  return (
    <>
      <div className="bg-teal-500 text-white my-12">
        <div className="-max-w-1366 mx-auto p-10 flex flex-wrap w-full justify-center relative text-center"  >
          <div className="absolute top-0 left-0 z-0">
            <img src={require("../images/man-2.png").default} />
          </div>
          <h4 className="relative z-10">Rates starting from 7.95% APR in just 2 minutes | Apply in seconds  | 0 Origination Fees
          </h4>
          <div className="absolute bottom-0 right-0">
            <img src={require("../images/man-1.png").default} />
          </div>
        </div>
      </div>
    </>
  );
}
